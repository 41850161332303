<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h4 text--primary">Компания</v-card-title>
      <v-card-text>
        АО «ИнтроСкан Технолоджи» – малое инновационное предприятие,
        основным видом деятельности которого являются научные исследования и разработки в области естественных и технических наук.
        Предприятие создано в партнерстве компаний ООО «ЭНТЭ» и ООО «Акустические Контрольные Системы»
      </v-card-text>
      <v-list>
        <v-list-item v-for="(l, i) in links" :key="i" :href="l.link" target="_blank">
          <v-list-item-avatar tile size="128">
            <v-img :src='l.logo'></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h5 text--primary">{{l.title}}</v-list-item-title>
            <v-list-item-subtitle>
              {{l.subtitle}}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{l.description}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
// import links from '../data/links';
import logoIntroscan from '@/assets/introscan-logo.svg';
import logoENTE from '@/assets/ente-logo.svg';
import logoAKS from '@/assets/aks-logo.svg';

export default {
  data() {
    return {
      links: [
        {
          title: 'Роботизированные',
          subtitle: 'АО «ИнтроСкан Технолоджи», год основания 2013',
          description: 'Системный Интегратор, Держатель технологий, Центр обработки данных внутритрубной диагностики.',
          logo: logoIntroscan,
          link: '#',
        },
        {
          title: 'Диагностические',
          subtitle: 'ООО «ЭНТЭ», год основания 2000',
          description: 'Эксперты, операторы РДК, специалисты НК. Исполнитель работ по диагностике объектов.',
          logo: logoENTE,
          link: 'https://www.ente-ltd.ru/',
        },
        {
          title: 'Системы',
          subtitle: 'ООО «АКС», год основания 1991',
          description: 'Разработчики, изготовители радиоэлектронной аппаратуры. Производство сканеров-дефектоскопов.',
          logo: logoAKS,
          link: 'https://www.acsys.ru/',
        },
      ],
    };
  },
};
</script>
